<template>
    <div :class="manHinh == 'doc' ? 'bg' : 'bg-ngang'">
        <ToolbarVue title="Thông tin thanh toán" />
        <div style="margin-left: 94px; margin-right: 94px; padding-top: 84px">
            <!--=================================Điền thông tin thanh toán==============================-->
            <div class="font-32 text-xs-left font-bold pb-4">
                Điền thông tin thanh toán
            </div>
            <ThongTinVue ref="refDienThongTin" v-if="manHinh == 'doc'"/>
            <ThongTinManHinhNgang ref="refDienThongTin" v-else/>
            <ViDienTuVue ref="refViDienTu" />
        </div>
        <!--=================================Tổng tiền==============================-->
        <div class="" style="bottom: 50px; width: 100%" :style="manHinh == 'doc' ? 'position: fixed' : 'position: relative; margin-top: 60px'">
            <div class="column justify-center align-center white--text">
                <div class="row justify-center">
                    <div style="font-size: 48px">Số tiền thanh toán:&nbsp</div>
                    <div style="font-size: 48px" class="font-bold">
                        {{
                            xeHetCho || xeKhongCoSoDoCho
                                ? `${$MoneyFormat(
                                      getSoChoMuaGhePhu * getGiaVeTheoDiemXuong
                                  )}đ`
                                : `${$MoneyFormat(tongTienTheoChoDaChon)}đ`
                        }}
                    </div>
                </div>
                <DxButton
                    id="btn-tieptuc"
                    text="tiếp tục"
                    type="default"
                    width="892px"
                    height="78px"
                    :disabled="
                        !getViDienTuDangChon || !getGiaVeTheoDiemXuong
                            ? true
                            : false
                    "
                    styling-mode="contained"
                    class="secondary--bg mt-4"
                    @click="thanhToan"
                />
                <!-- @click="$router.push('thong-tin-thanh-toan')" -->
            </div>
        </div>
        <!--=================================Popup Thanh toán Qr==============================-->
        <PopupVue
            height="auto"
            width="623px"
            v-model:dialog="dialogThanhToanQr"
            title=""
            :closeOnOutsideClick="false"
            :useButton="false"
            :showCloseButton="true"
            position="center"
            @close="closePopupThanhToan"
        >
            <template #content>
                <QuetMaQRVue
                    :isShowDialog="dialogThanhToanQr"
                    @EmitError="error"
                />
            </template>
        </PopupVue>
        <!-- ====================================Popup Thông báo ========================================= -->
        <PopupsVue
            :Params="{
                ShowButtonLeft: false,
                ShowButtonRight: false,
                ShowButtonClose: false,
            }"
            Width="350"
            Height="auto"
            :Model="dialogThongBao"
        >
            <template #popups
                ><NotificationVue
                    :Params="ParamThongBao"
                    @click="closeDialogThongBao"
                    :showButtonLeft="false"
            /></template>
        </PopupsVue>
    </div>
</template>
<script>
import ToolbarVue from "../components/_Common/Toolbar";
import ViDienTuVue from "../components/ThongTinThanhToan/ViDienTu";
import ThongTinVue from "../components/ThongTinThanhToan/ThongTin";
import PopupVue from "../components/_Common/Popup";
import PopupsVue from "@sonphat/common-v1/components/Popups";
import NotificationVue from "@sonphat/common-v1/components/Notification";
import QuetMaQRVue from "../components/QuetMaQR";
import { DxButton } from "devextreme-vue";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import ThongTinManHinhNgang from "../components/ThongTinThanhToan/ThongTinManHinhNgang.vue"
export default {
    components: {
        ToolbarVue,
        ViDienTuVue,
        DxButton,
        ThongTinVue,
        PopupVue,
        QuetMaQRVue,
        PopupsVue,
        NotificationVue,
        ThongTinManHinhNgang
    },
    emits: ["EmitError"],
    props: {},
    data() {
        return {
            dialogThanhToanQr: false,
            dialogThongBao: false,
            errorCode: null,
            xeHetCho: false,
            xeKhongCoSoDoCho: false,
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message_title: "Đã xảy ra lỗi",
                message: "Vui lòng kiểm tra lại",
            },
        };
    },
    methods: {
        ...mapActions("idleTime", ["stopIdleTimer"]),
        ...mapActions("thongTinThanhToan", ["huyThanhToan"]),
        ...mapActions("timKiemChuyenDi", ["kiemTraTrangThaiChuyenDi"]),
        ...mapMutations("thongTinThanhToan", ["setModel"]),

        async thanhToan() {
            //Kiểm tra để mua vé
            let check = await this.kiemTraTrangThaiChuyenDi({
                idXe: this.$route.query.chuyenDi_Id,
                soKhach: this.getSoChoMuaGhePhu || 0,
            });
            if (!check.data) {
                this.thongBao("Error", check.message);
                this.dialogThongBao = true;
                return;
            }
            let rs =
                this.$refs.refDienThongTin.$refs.formValidation.instance.validate();
            let refDiemXuong =
                this.$refs.refDienThongTin.$refs.refLuaChonDiemXuong.$refs.formValidation.instance.validate();
            if (rs.isValid && refDiemXuong.isValid) {
                let ttDienThongTin = this.$refs.refDienThongTin;
                if (ttDienThongTin) {
                    this.setModel({
                        key: "thongTinThanhToan",
                        value: ttDienThongTin.model,
                    });
                }
                this.dialogThanhToanQr = true;
            }
        },
        error(errorCode, message) {
            this.dialogThanhToanQr = false;
            this.errorCode = errorCode;
            if (message) {
                this.thongBao("Error", message);
            }
        },
        async closePopupThanhToan() {
            try {
                await this.huyThanhToan();
                this.dialogThanhToanQr = false;
            } catch (error) {
                this.$stopLoading;
                this.dialogThanhToanQr = false;
            }
        },
        thongBao(state, message_title, title) {
            this.ParamThongBao = {
                state: state,
                title: "Thông báo!",
                message_title: message_title,
            };
            this.dialogThongBao = true;
        },
        closeDialogThongBao() {
            this.dialogThongBao = false;
            if (this.errorCode == 1106) {
                this.errorCode = null;
                this.$router.push({
                    path: "chon-vi-tri-cho-ngoi",
                    query: this.$route.query,
                });
            }
        },
    },
    computed: {
        ...mapGetters("thongTinThanhToan", [
            "getViDienTuDangChon",
            "getGiaVeTheoDiemXuong",
            "getSoChoMuaGhePhu",
            "getGoiYDanhSachDiemDung",
        ]),
        ...mapGetters("soDoCho", ["tongTienTheoChoDaChon"]),
        ...mapState("ManHinh", ["manHinh"])
    },
    mounted() {
        this.setModel({ key: "goiYDanhSachDiemDung", value: [] });
        this.getGoiYDanhSachDiemDung.forEach((e) => {
            e.active = false;
        });
        this.setModel({ key: "viDienTuDangChon", value: null });
        if (this.$route.query) {
            this.xeHetCho =
                this.$route.query.xeHetCho == "true"
                    ? true
                    : this.$route.query.xeHetCho == "false"
                    ? false
                    : null;
            this.xeKhongCoSoDoCho =
                this.$route.query.xeKhongCoSoDoCho == "true"
                    ? true
                    : this.$route.query.xeKhongCoSoDoCho == "false"
                    ? false
                    : null;
        }
    },
};
</script>
<style lang='scss' scoped>
.bg {
    background-image: url("../assets/images/vector-003.jpg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: auto;
    height: 100vh;
}

.bg-ngang {
    background-image: url("../assets/images/vector-003.jpg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 60%;
    height: 100vh;
    overflow: scroll;
}
::v-deep#btn-tieptuc span {
    font-size: 28px;
}
::v-deep#btn-tieptuc {
    border-radius: 10px;
}
</style>